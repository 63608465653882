<template>
    <div class="page-body file-maanger-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app section-body">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-50 d-flex justify-content-between align-items-center">
                        <span v-if="isEditable" class="label-app-list-header">Edit Application</span>
                        <span v-else class="label-app-list-header">Add New Application</span>
                    </div>
                </div>

                <div v-if="!loading">
                    <ValidationObserver ref="form">
                        <form
                            style="box-shadow: none"
                            class="p-0"
                            @submit.prevent="submit"
                            @change="isDataChanged = true"
                        >
                            <div class="section-body add-new-app-form">
                                <Input
                                    label="Android App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="Android App ID"
                                    placeholder="Example: us.zoom.videomeeting"
                                    v-model="functionInfo.androidId"
                                />
                                <Input
                                    label="IOS App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                    name="iosAppID"
                                    placeholder="Example: us.zoom.video.meeting"
                                    v-model="functionInfo.iosId"
                                />
                                <Input
                                    label="Windows App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="Windows App ID"
                                    placeholder="Example: us.zoom.videomeeting"
                                    v-model="functionInfo.windowsId"
                                />
                                <Input
                                    label="Name"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="name"
                                    placeholder="Example: zoom"
                                    v-model="functionInfo.functionName"
                                    required
                                />
                                <div class="app-img-upload block field-container d-flex align-items-center">
                                    <label class="me-3 d-flex justify-content-end mb-5 add-new-app-label"
                                        >Icon Art*</label
                                    >
                                    <div class="d-flex align-items-center w-70">
                                        <div class="img-container d-flex justify-content-center align-items-center">
                                            <svg
                                                v-if="!uploadedImg && !showImgLoader"
                                                width="50"
                                                height="55"
                                                viewBox="0 0 71 55"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.19815 8.79779H59.9507C60.833 8.79779 61.6211 9.15091 62.1943 9.71533C62.7663 10.2798 63.1251 11.08 63.1251 11.9263V51.8709C63.1251 52.7412 62.7663 53.5175 62.1943 54.0825C62.1469 54.1292 62.0984 54.1764 62.0273 54.2231C61.4778 54.7175 60.7388 54.9994 59.927 54.9994H3.17446C2.29157 54.9994 1.50345 54.6469 0.930843 54.0825C0.358239 53.518 0 52.7184 0 51.8715V11.9269C0 11.0566 0.358239 10.2803 0.930843 9.7159C1.50345 9.15148 2.31526 8.79836 3.17446 8.79836H3.19815V8.79779ZM16.3045 19.6431C18.8202 19.6431 20.8587 21.6525 20.8587 24.1323C20.8587 26.6121 18.8202 28.622 16.3045 28.622C13.7887 28.622 11.7502 26.6121 11.7502 24.1323C11.7496 21.653 13.7887 19.6431 16.3045 19.6431ZM35.4708 38.9092L44.5805 23.3845L54.2575 47.5122L9.09348 47.5116V44.5192L12.8897 44.333L16.6841 35.1672L18.5805 41.7148H24.2747L29.2074 29.1824L35.4708 38.9092ZM12.6487 3.71629C11.5988 3.71629 10.7633 2.86995 10.7633 1.85786C10.7633 0.822995 11.6225 0 12.6487 0H69.1152C70.1645 0 71 0.846916 71 1.85843V41.0974C71 42.1328 70.1414 42.9564 69.1152 42.9564C68.0648 42.9564 67.2292 42.1094 67.2292 41.0974V3.74021H12.6487V3.71629ZM59.3538 12.5152H3.79444V51.2598H59.3532L59.3538 12.5152Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <img
                                                v-if="uploadedImg && !showImgLoader"
                                                :src="uploadedImg"
                                                style="max-height: 100%"
                                                alt="image not uploaded"
                                            />
                                            <div class="d-flex flex-column" v-if="showImgLoader">
                                                <div
                                                    class="d-flex justify-content-center align-items-center img-loader-main"
                                                >
                                                    <b-spinner variant="primary" class="img-loader"></b-spinner>
                                                </div>
                                                <span class="img-loader-text">Uploading...</span>
                                            </div>
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Delete"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="top"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined img-delete"
                                                    role="button"
                                                    @click="resetFileUpload()"
                                                >
                                                    delete
                                                </span>
                                            </ejs-tooltip>
                                        </div>

                                        <button
                                            type="button"
                                            class="no-bg text-button btn-add-new-app ms-3 w-70"
                                            @click="chooseFile"
                                        >
                                            Choose file
                                        </button>
                                        <input
                                            id="fileid"
                                            type="file"
                                            name="upload"
                                            ref="upload"
                                            size="100000"
                                            @change="uploadFile"
                                            accept=".jpg, .jpeg, .png"
                                            hidden
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="section-footer add-new-app-form my-4">
                                <div class="d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        class="primary btn-save-application"
                                        :disabled="showImgLoader"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="no-bg text-button btn-cancel px-4"
                                        @click="$emit('cancel')"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>

            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 12rem"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { memberProfile } from '../../util/apiRequests';
    import { ValidationObserver } from 'vee-validate';
    import { thirdPartyUpdateNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';

    export default {
        name: 'AddThirdPartyAppModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            applicationId: {
                type: [Number, null],
            },
        },
        data() {
            return {
                loading: true,
                isDataChanged: false,
                isEditable: false,
                uploadedImg: '',
                showImgLoader: false,
                functionInfo: {
                    functionName: '',
                    application: '',
                    androidId: '',
                    iosId: '',
                    windowsId: '',
                    image: '',
                    functionType: '3rdParty',
                },
            };
        },
        methods: {
            chooseFile() {
                this.$refs.upload.click();
            },
            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.$refs.upload.value = '';
                this.functionInfo.image = '';
            },
            async fetchApplication() {
                try {
                    const endpoint = memberProfile.getSpecificFunction(this.applicationId, '3rdParty');
                    const functionResponse = await this.$api.get(endpoint);

                    if (functionResponse.data.success) {
                        let { data } = functionResponse.data;
                        this.functionInfo = {
                            functionId: data[0].id,
                            functionName: data[0].function_name,
                            androidId: data[0].android_id,
                            iosId: data[0].ios_id,
                            windowsId: data[0].windows_id,
                            image: data[0].image,
                            application: data[0].application,
                            functionType: data[0].function_type,
                        };
                        this.uploadedImg = data[0].imageUrl;
                    }
                } catch (e) {
                    this.$toasted.error('Failed to get application details !');
                }
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (file.size / 1024 / 1024 > 10) {
                        this.$toasted.error('Maximum 10 MB file size allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    //HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const formData = new FormData();
                        formData.append('file', file);

                        const endpoint = memberProfile.uploadProfileFunctionsImage();
                        const uploadImgResponse = await this.$api.post(endpoint, formData);
                        if (!uploadImgResponse.data.success) {
                            throw uploadImgResponse.data.error;
                        }

                        //Coverts into base64 for rendering
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.uploadedImg = event.target.result;
                        };

                        reader.readAsDataURL(file);

                        this.functionInfo.image = uploadImgResponse.data.s3_link;
                        this.$toasted.success('Application image uploaded successfully !');
                    } catch (e) {
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            async submit() {
                try {
                    if (!this.isEditable) {
                        await this.addNewApplication();
                    } else {
                        await this.updateApplication();
                    }
                } catch (err) {
                    this.$toasted.error(err);
                }
            },
            async addNewApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (
                        this.functionInfo.androidId.length == 0 &&
                        this.functionInfo.iosId.length == 0 &&
                        this.functionInfo.windowsId.length == 0
                    ) {
                        this.$toasted.error('Please fill any one of the app ID');
                        return;
                    }

                    if (!this.isFileAvailable()) {
                        this.$toasted.error('Please upload file');
                        return;
                    }
                    this.loading = true;

                    // HTTP call for add new 3rd party application
                    const endpoint = memberProfile.createFunction();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }
                    this.$emit('cancel');
                    this.$toasted.success('Application added successfully !');
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            },
            async updateApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.isFileAvailable() && !this.uploadedImg) {
                        this.$toasted.error('Please upload file');
                        return;
                    }

                    this.loading = true;

                    const endpoint = memberProfile.updateFunction();
                    const saveFunctionResponse = await this.$api.put(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    if (this.isDataChanged) {
                        await this.send3rdPartyUpdateNotification();
                    }

                    this.$emit('cancel');
                    this.$toasted.success('Application updated successfully !');
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            },
            async send3rdPartyUpdateNotification() {
                try {
                    const endpoint = memberProfile.getListofUsers(this.applicationId);
                    const response = await this.$api.get(endpoint);
                    const emails = response?.data?.data;
                    if (emails?.length) {
                        await thirdPartyUpdateNotification(emails);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to send update notification!');
                }
            },
        },
        async created() {
            if (this.applicationId) {
                this.isEditable = true;
                await this.fetchApplication();
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .file-maanger-modal .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
