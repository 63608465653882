<template>
    <NoButtonModal :open="true">
        <button class="close-corner-x" type="button" @click.prevent="alertResponse('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>

        <div v-if="!loading">
            <h1>Delete Alert</h1>
            <p>Are you sure you want to delete this item?</p>
            <p v-if="indexTodelete && dashboardCount <= 1">This item is linked with {{ dashboardCount }} dashboard</p>
            <p v-else-if="indexTodelete">This item is linked with {{ dashboardCount }} dashboards</p>
            <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
            <button @click="alertResponse('handleDelete')" class="button primary">Delete</button>
        </div>

        <div
            v-if="loading"
            class="d-flex flex-column justify-content-center align-items-center loader-main"
            style="min-height: 12rem"
        >
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { memberProfile } from '../../util/apiRequests';

    export default {
        name: 'DeleteThirdPartyAppModal',
        components: { NoButtonModal },
        data() {
            return {
                loading: true,
                dashboardCount: 0,
            };
        },
        props: {
            indexTodelete: {
                type: Number,
            },
        },
        methods: {
            alertResponse(action) {
                if (action === 'handleDelete') {
                    this.$emit('handleDelete');
                } else {
                    this.$emit('cancel');
                }
            },
            async getDashboardCount() {
                let response = await this.$api.get(memberProfile.getDashboardCount(this.indexTodelete));
                if (response?.data?.data) {
                    this.dashboardCount = response?.data?.data;
                }
            },
        },
        async created() {
            if (this.indexTodelete) {
                await this.getDashboardCount();
            }
            this.loading = false;
        },
    };
</script>
